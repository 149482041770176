import React from "react";
import { Link } from "gatsby";

import classNames from "classnames";
import styles from "./Pagination.module.scss";

const Pagination = ({
    currentPage,
    numPages
}) => {

    const cx = classNames.bind(styles);

    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = (currentPage - 1).toString();
    const nextPage = (currentPage + 1).toString();

    return(
        <div className={styles.wrapper}>
            {!isFirst && (
                <Link
                    className={styles.page}
                    to={`../${prevPage}`}
                    rel="prev"
                >
                    ← Previous Page
                </Link>
            )}
            {Array.from({ length: numPages }, (_, i) => (
                currentPage !== i + 1 ?
                    <Link
                        className={styles.page}
                        key={`pagination-number${i + 1}`}
                        to={`../${i + 1}`}
                    >
                        {i + 1}
                    </Link>
                :
                    <span
                        className={cx(styles.page, styles.current)}
                    >
                        {i + 1}
                    </span>
            ))}
            {!isLast && (
                <Link
                    className={styles.page}
                    to={`../${nextPage}`}
                    rel="next"
                >
                    Next Page →
                </Link>
            )}
        </div>
    )
};

export default Pagination;