import React from "react";

import Layout from "../components/layout/Layout";
import Posts from "../components/posts/Posts";
import Pagination from "../components/pagination/Pagination";

import styles from "./IndexBody.module.scss";

const IndexBody = ({
    currentPage,
    data,
    location,
    numPages,
    title
}) => {

    return (
        <Layout
            location={location}
            className={styles.wrapper}
        >
            <div className={styles.posts}>
                <h2 className={styles.title}>
                    {title}
                    {currentPage !== 1 &&
                        <span>: page {currentPage}</span>
                    }
                </h2>
                <Posts
                    data={data}
                />
            </div>

            {numPages > 1 &&
                <Pagination
                    currentPage={currentPage}
                    numPages={numPages}
                />
            }
        </Layout>
    )
}
            
export default IndexBody;