import React from "react";
import { graphql } from "gatsby";

import IndexBody from "./IndexBody";

export const query = graphql
`query POSTS(
    $cat: String,
    $type: String,
    $skip: Int!,
    $limit: Int!
) {
    archive: allMdx(
        sort: {
            fields: [frontmatter___date],
            order: DESC
        }
        filter: {
            frontmatter: {
                published: {eq: true}
            }
        }
        limit: $limit
        skip: $skip
    ) {
        nodes {
            slug
            frontmatter {
                image {
                    childImageSharp {
                        gatsbyImageData(
                            width: 254,
                            quality: 80,
                            formats: [WEBP, JPG],
                            layout: CONSTRAINED
                        )
                    }
                }
                title
                date(formatString: "MMM D, YYYY")
                dateSimple: date(formatString: "YYYY-MM-DD")
                released(formatString: "MMM Do, YYYY")
                releasedSimple: released(formatString: "YYYY-MM-DD")
                category
                excerpt
            }
        }
    }

    byType: allMdx(
        sort: {
            fields: [frontmatter___released, frontmatter___date],
            order: [DESC, DESC]
        }
        filter: {
            frontmatter: {
                published: {eq: true},
                type: {eq: $type}
            }
        }
        limit: $limit
        skip: $skip
    ) {
        nodes {
            slug
            frontmatter {
                image {
                    childImageSharp {
                        gatsbyImageData(
                            width: 254,
                            quality: 80,
                            formats: [WEBP, JPG],
                            layout: CONSTRAINED
                        )
                    }
                }
                title
                date(formatString: "MMM D, YYYY")
                dateSimple: date(formatString: "YYYY-MM-DD")
                startDate(formatString: "MMM D, YYYY")
                startDateSimple: startDate(formatString: "YYYY-MM-DD")
                endDate(formatString: "MMM D, YYYY")
                endDateSimple: endDate(formatString: "YYYY-MM-DD")
                productUrl
                released(formatString: "MMM Do, YYYY")
                releasedSimple: released(formatString: "YYYY-MM-DD")
                category
                excerpt
                type
                series {
                    title
                }
                season
                episode
                media
            }
        }
    }

    byCategory: allMdx(
        sort: {
            fields: [frontmatter___startDate, frontmatter___released, frontmatter___date],
            order: [ASC, DESC, DESC]
        }
        filter: {
            frontmatter: {
                published: {eq: true},
                category: {eq: $cat}
            },
            fields: {
                due: {ne: "past"}
            }
        }
        limit: $limit
        skip: $skip
    ) {
        nodes {
            slug
            frontmatter {
                image {
                    childImageSharp {
                        gatsbyImageData(
                            width: 254,
                            quality: 80,
                            formats: [WEBP, JPG],
                            layout: CONSTRAINED
                        )
                    }
                }
                title
                date(formatString: "MMM D, YYYY")
                dateSimple: date(formatString: "YYYY-MM-DD")
                startDate(formatString: "MMM D, YYYY")
                startDateSimple: startDate(formatString: "YYYY-MM-DD")
                endDate(formatString: "MMM D, YYYY")
                endDateSimple: endDate(formatString: "YYYY-MM-DD")
                startTime
                endTime
                productUrl
                released(formatString: "MMM Do, YYYY")
                releasedSimple: released(formatString: "YYYY-MM-DD")
                category
                excerpt
                type
                series {
                    title
                }
                season
                episode
                media
            }
        }
    }
}
`

const Debug = ({ pageContext, data, location }) => {

    const { currentPage, numPages, pageHeading, dataQuery } = pageContext

    return (
        <IndexBody
            currentPage={currentPage}
            data={data[dataQuery]}
            location={location}
            numPages={numPages}
            title={pageHeading}
        />
    )
}
            
export default Debug;